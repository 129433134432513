import { Suspense } from 'react';
import { LoadingIndicator } from './LoadingIndicator';
import ExampleComponent from './ExampleComponent';

const MainComponent = () => {
    return (
        <div className={'overflow-hidden display-flex flex-column'}>
            <Suspense fallback={<LoadingIndicator />}>
                <ExampleComponent />
            </Suspense>
        </div>
    );
};

export default MainComponent;
