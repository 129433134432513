import { CLOSE_BUTTON, sendMessage } from '../MessageHandler';

interface CloseButtonProps {
    onClickCallback?: () => void;
}

const CloseButton = (props: CloseButtonProps) => {
    const onClick = () => {
        sendMessage({
            type: CLOSE_BUTTON,
            payload: { show: false },
        });

        if (props.onClickCallback) props.onClickCallback();
    };
    return (
        <div className={'overflow-auto padding-left-15 padding-top-15 padding-bottom-25 padding-right-15'}>
            <button className="btn btn-primary" type="button" onClick={onClick}>
                <span className="rioglyph rioglyph-workshop spinning" />
                <span>Close Dialog</span>
            </button>
        </div>
    );
};

export default CloseButton;
