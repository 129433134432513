import useFetchExample from './useFetchExample';
import CloseButton from './CloseButton/CloseButton';
import ContentLoader from '@rio-cloud/rio-uikit/lib/es/ContentLoader';
import InternalErrorState from './InternalErrorState';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import { gaPush } from '../../configuration/setup/googleAnalytics';

const ExampleSkeletonScreen = () => {
    return (
        <>
            <ContentLoader height={20} width={370} className="padding-left-15 margin-top-15" />
            <ContentLoader height={35} width={150} className="padding-left-15 margin-top-50" />
        </>
    );
};

// TODO To be removed/updated when a new project is created.
const ExampleComponent = () => {
    const { isLoading, hasErrors, exampleText, triggerReload } = useFetchExample();

    const showSuccessMessage = () => Notification.success('Button clicked');
    const showSuccessMessageAndSendEventToGA = () => {
        showSuccessMessage();
        gaPush({
            event: 'stencil_test_event',
            parameters: {
                element_name: 'ExampleComponent',
                component_name: 'Button',
                trigger: 'click',
            },
            userProps: {},
        });
    };

    if (hasErrors) {
        return <InternalErrorState reloadTriggerFunction={triggerReload} />;
    }
    if (isLoading) {
        return <ExampleSkeletonScreen />;
    }
    return (
        <div className={'overflow-auto padding-left-15 padding-top-15 padding-bottom-25 padding-right-15'}>
            <span>{exampleText}</span>
            <CloseButton onClickCallback={showSuccessMessageAndSendEventToGA} />
        </div>
    );
};

export default ExampleComponent;
