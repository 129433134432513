import './App.css';

import { IntlProvider } from 'react-intl';
import { DEFAULT_LOCALE } from '../../configuration/lang/lang';
import { useAppSelector } from '../../configuration/setup/hooks';
import { getDisplayMessages, getLocale } from '../../configuration/lang/langSlice';
import MainComponent from './MainComponent';
import MessageHandler from './MessageHandler';
import NotificationsContainer from '@rio-cloud/rio-uikit/lib/es/NotificationsContainer';

const App = () => {
    const userLocale = useAppSelector(getLocale);
    const displayMessages = useAppSelector(getDisplayMessages);

    if (!displayMessages || !userLocale) {
        return null;
    }

    return (
        <IntlProvider defaultLocale={DEFAULT_LOCALE} key={userLocale} locale={userLocale} messages={displayMessages}>
            <MainComponent />
            <MessageHandler />
            <NotificationsContainer />
        </IntlProvider>
    );
};

export default App;
